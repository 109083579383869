@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P');
@import url('https://fonts.googleapis.com/css2?family=VT323');

.App {
  text-align: center;
  font-family: 'Press Start 2P', 'VT323';
  color: white;
}

* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

.overlay {
  opacity: 0.85;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.moving-background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(./assets/background/parallax-bg.gif);
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 40% 40%;
}

@media screen and (max-width: 768px) {
    .App {
      width: 100vW;
      height: 100vh;
    }
}